.addtestpop .upload-btn {
  width: 5rem;
  height: 40px;
}
.addtestpop .upload-btn .el-upload {
  width: 5rem;
  height: 40px !important;
  border: none !important;
}
.addtestpop .btnBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
