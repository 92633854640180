.ylzpList {
  width: 400px;
  overflow-x: auto;
  white-space: nowrap;
}
.ylzpList .ylzpList_1 {
  width: 80px;
  height: 80px;
  margin-right: 10px;
}
