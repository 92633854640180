






























































































































































































































































































.addtestpop {
  .upload-btn {
    width: 5rem;
    height: 40px;
    .el-upload {
      width: 5rem;
      height: 40px !important;
      border: none !important;
    }
  }
  .btnBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
